import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Ggbrewery = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B9A113, #D8BB15, #ECCC16, #D8BB15, #B9A113 )",
        icons: "#f8df10",
        navClass: "gg",
      }}
      seo={{
        title: "GG Brewery",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/gg_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/gg-brewery/",
      }}
    >
      <PortfolioHeader name="gg" height="295" />
      <section className="container-fluid gg_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>GG Brewery</h1>
              <ul>
                <li>Brewery logo</li>
                <li>Beer logos</li>
                <li>Label design</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Good Game Brewery brews beer for players. Our task was to design
                the entire brand from scratch. We designed the brewery logo
                (Good Game Brewery), as well as logos and labels for each of the
                4 beers it has to offer (‘Mid or Feed’, ‘Clutchmeister’, ‘Battle
                Royale’ and ‘Runic Hammer’).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid gg_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/gg_main_2.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/gg_main_3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 73 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 66 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 66 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 81 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Yellow CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 5 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 6 : 0} />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 99 : 0}
                      />
                      <span>0</span>
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 19 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 20 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 19 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 248 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 223 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 16 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>HEX</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 131413 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>f8df10</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gg_section_4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="m-auto px-4 text">
            <h4>Mid or Feed</h4>
            <span>Pale ale</span>
            <p className="mb-5">
              The name, logo and label refer to the most popular MOBA game:
              League of Legends. In the design of the label you can see a
              reference to the strongest monster to be found on Summoner's Rift,
              Baron Nashor.
            </p>

            <h4>Clutchmeister</h4>
            <span>Pils</span>
            <p>
              The name, logo and label refer to the most popular FPS game; CS
              GO. On the label you can see a reference to the unit of
              anti-terrorists storming the headquarters of terrorists.
            </p>
          </div>
          <div className="img-1">
            <img
              src={require("../../../assets/img/portfolio/gg_main_4.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="gg_section_5">
        <div className="d-flex justify-content-between">
          <div className="img-1">
            <img
              src={require("../../../assets/img/portfolio/gg_main_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="mr-auto px-4 text">
            <h4>Runic Hammer</h4>
            <span>Dunkelweizen</span>
            <p className="mb-5">
              This is a fantastic beer on which label you can see the dwarven
              fortress Karaz Grong. Runic Hammer is definitely King Thorgin's
              favorite beer.
            </p>

            <h4>Battle Royale</h4>
            <span>American Pale Ale</span>
            <p>
              The name, logo and label refer to extremely popular games such as
              Fortnite or Playerunknown's Battlegrounds. On the label of the
              beer you can see parachutists landing on the island where the
              battle will take place, and only one of them will actually
              survive.
            </p>
          </div>
        </div>
      </section>
      <section className="gg_section_6">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Website</h4>
              <p>
                We were also responsible for creating the website of this
                amazing brewery! GL and GG!
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/gg_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gg_section_8">
        <img
          src={require("../../../assets/img/portfolio/gg_main_8.png")}
          className="img-fluid"
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Ggbrewery;
